import React, { Component } from "react";
import ScrollUp from "../../assets/images/scroll-up-icon.svg";

export default class ScrollTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 100) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <div className="scroll_top">
        <div onClick={() => this.scrollToTop()}>
              <img src={ScrollUp} alt={"icon"} />
          </div>
      </div>
    );
  }
}
